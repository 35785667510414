<template>
  <div class="login">
    <div class="l-pos">
      <img class="logo" alt="logo" src="../assets/login/logo.png" />
      <p>金科玉律收费管理系统</p>
      <div class="login-box">
        <div class="login-input">
          <img src="../assets/login/user.png" alt="">
          <van-field class="input-class" v-model="loginForm.username" placeholder="请输入用户名" />
        </div>
        <div class="login-input">
          <img src="../assets/login/pwd.png" alt="">
          <van-field type="password" class="input-class" v-model="loginForm.password" placeholder="请输入密码" />
        </div>
        <div class="login-input nonestyle">
          <img src="../assets/login/code.png" alt="">
          <van-field class="input-class login-input1" v-model="loginForm.verifyCode" placeholder="请输入验证码" />
          <div class="code-box" @click="AxiosImg"><img :src="`data:image/png;base64,${img_src}`" alt=""></div>
        </div>
        <van-button class="login-btn" type="primary" size="large" @click='login'>登录</van-button>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { Toast ,Field ,Button } from 'vant';
import Cookies from 'js-cookie'
export default {
  name: "login",
  data() {
    return {
      loginForm: {
        // username: 'admin',
        // password: 'qwer1234',
        username: '',
        password: '',
        key: '',
        verifyCode: '',
        serviceId: 82
      },
      img_src:"",
    };
  },
  methods: {
    async login(){
      Toast.loading({
          duration: 0,
          message: '正在登陆...',
          forbidClick: true,
      });
      await this.$post('/admin/api/login',this.loginForm).then(res => {
        if (res.code === '200') {
          Cookies.set('Admin-Token', res.data.token ,{ expires: 7 })
          Toast.clear();
          this.$router.push({ path: '/' })

        }else{
          Toast(res.message)
          this.AxiosImg()
        }
      })
    },
    async AxiosImg() {
      setTimeout(function(){
        Toast.clear();
      },2000)
      await this.$get('/admin/api/validCode').then(res => {
        if (res.code === '200') {
          this.img_src = res.data.img
          this.loginForm.key = res.data.key
        }
      })
    },
  },
  beforeRouteEnter: (to, from, next) => {
      next(vm => {
          if(Cookies.get('Admin-Token')){
            next('/')
          }else{
              next();
          }
      })
  },
  mounted () {
    this.AxiosImg()
  },
  components: {
    [Toast.name]: Toast,
    [Field.name]: Field,
    [Button.name]: Button,
  },
};
</script>

<style lang="scss" scoped>
  .login{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    text-align: center;
    background: #fff url(../assets/login/bg.png);
    background-size: 100% 100%;
    position: relative;
    .l-pos{
      position: absolute;
      left: 50%;
      top: 50%;
      // transform: ;
      transform: translate(-50%, -50%);
    }
    .logo{
      width: 60px;
      height: 60px;
      // margin-top: 120px;
    }
    p{
      font-size: 25px;
      font-weight: bold;
      color: #585858;
      padding: 0;
      margin: 0;
      margin:14px 0 60px;
    }
    .login-box{
      margin: 0 auto;
      width: 285px;
      // padding-bottom: 40px;
      .login-input{
        width: 285px;
        height: 44px;
        // overflow: hidden;
        border-radius: 5px;
        border:1px solid #CFCFCF;
        // box-shadow: 0px 1px 1px 0px rgba(158, 168, 184, 0.5);
        margin-bottom: 22px;
        position: relative;
        &.nonestyle{
          border:none;
          box-shadow: none;
        }
        img{
          width: 14px;
          height: 14px;
          position: absolute;    
          z-index: 11;
          left: 8px;
          top: 15px;
        }
        .input-class{
          padding-left: 30px;
          border-radius: 5px;
          &.login-input1{
            width: 200px;
            border-radius: 5px;
            border:1px solid #CFCFCF;
            // box-shadow: 0px 1px 1px 0px rgba(158, 168, 184, 0.5);
          }
        }
        .code-box{
          width: 76px;
          height: 44px;
          background: #fff;
          position: absolute;
          right: 0;
          top: 0;
          overflow: hidden;
          border-radius: 5px;
          font-size: 0;
          border: 1px solid #CFCFCF;
          // box-shadow: 0px 1px 1px 0px rgba(158, 168, 184, 0.5);
          img{
            width: 80%;
            height: 80%;
            margin: 5% 10%;
            top: 0;
            left: 0;
          }
        }
      }
      .login-btn{
        background: #E48E34;
        border-color: #E48E34;
        height: 42px;
        font-size: 14px;
        border-radius: 5px;
      }
    }
    ::-webkit-input-placeholder { /* WebKit browsers */
      color: #C6C6C6;
      font-size: 14px;
    }
    
    ::-moz-placeholder { /* Mozilla Firefox 19+ */
      color: #C6C6C6;
      font-size: 14px;
    }
    
    :-ms-input-placeholder { /* Internet Explorer 10+ */
      color: #C6C6C6;
      font-size: 14px;
    }
  }
</style>
